.swiper-container{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
}
.sliderImage{
    width: 1000px;
    height:600px;
    border-radius: 10px;
}
.right-arrow{
    position: absolute;
    top: 50%;
    right:32px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}
.left-arrow{
    position: absolute;
    top: 50%;
    left:32px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}
.slide{
    opacity: 0;
    transition-duration: 1s ease;
    animation: slide-up 1s ease 0.5s;
}

.slide.active{
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
    animation: slide-right 1s ease 0.5s;
}


@media only screen and (max-width: 768px){
.sliderImage{
    width: 500px;
    height:300px;
    border-radius: 10px;
}
}