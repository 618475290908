.order-container {
    margin-top: 100px;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.order-message {
    font-size: 2.2rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: rgb(170, 9, 9);
}

.order-details-container {
    border: 2px solid rgb(170, 9, 9);
    width: 40vw;
}

.order-details-container > div {
    padding: 10px;
}

.order-id {
    color: rgb(253, 232, 153);
    font-weight: 600;
    background:rgb(170, 9, 9);
}

.order-date {
    color:rgb(170, 9, 9);
    font-weight: 600;
    border-bottom: 2px solid rgb(170, 9, 9);
}

.order-items-title {
    color:rgb(170, 9, 9);
    font-weight: 600;
}

.order-items {
    margin-left: 12px;
    margin-right: 10px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.order-items > div {
    padding: 10px;
    margin-left: 5px;
}

.order-image {
    height: 100px;
    width: 100px;
    margin: -12px;
    border: 2px solid rgb(170, 9, 9);
}

.order-product-name {
    font-weight: 600;
}

.single-order-item {
    background: rgb(170, 9, 9);
    width: 30vw;
    position: relative;
    left: 10px;
    height: 100px;
    bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.order-status {
    border-top: 2px solid rgb(170, 9, 9);
    color: rgb(170, 9, 9);
    font-weight: 600;
}

.order-total {
    font-weight: 600;
    color: white;
    background: rgb(170, 9, 9);
}

.continue-shopping-button {
    margin-top: 30px;
    border: 2px solid rgb(170, 9, 9);
    background: none;
    color: rgb(170, 9, 9);
    height: 50px;
    font-weight: 600;
}

.continue-shopping-button:hover {
    animation: redfade 1s forwards;
}