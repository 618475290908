@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.cart-background {
    height: 100vh;
    width: 100vw;
}

.cart-foreground {
    padding-top: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.cart-title {
    font-size: 2.2rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: rgb(170, 9, 9);
}

.cart-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 15px;
    border: 2px solid rgb(170, 9, 9);
    background: rgb(170, 9, 9);
    margin-bottom: 20px;
    height: 152px;
    width: 400px;
}

.cart-image {
    height: 148px;
    width: 148px;
    margin: 0px;
}

.cart-item-container {
    display: flex;
    flex-direction: row;
}

.cart-item-info {
    color: white;
    width: 300px;
    height: 150px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-left: 10px;
}

.cart-edit {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -5px;
    margin-bottom: -5px;
}

.cart-edit-button {
    border: none;
    color: white;
    background: transparent;
}

.cart-edit-button > span {
    font-size: 17px;
    margin-left: 10px;
    position: relative;
    top: 1px;
}

.cart-edit-form {
    display: flex;
    flex-direction: row;
    margin-bottom: -9px;
    margin-top: 2px;
}

.cart-edit-form > input {
    height: 18px;
    width: 55px;
    position: relative;
}

.cart-edit-form > input:focus {
    outline: none;
}

.cart-edit-form > button {
    border: none;
    background: transparent;
    color: white;
    font-size: 19px;
    position: relative;
    bottom: 6px;
}

.cart-delete-button {
    height: 152px;
    border: 2px solid rgb(170, 9, 9);
    background: transparent;
    color: rgb(170, 9, 9);
    border-radius: 0px 0px 0px 0px;
}

.cart-delete-button:hover {
    animation: deletefade 1s forwards;
}

.cart-total-price {
    font-size: 30px;
    font-weight: 600;
    position: relative;
    top: 8px;
    margin-right: 30px;
    color: rgb(170, 9, 9);
}

.cart-bottom-container {
    display: flex;
    flex-direction: row;
    width: 440px;
    justify-content: space-between;
    position: relative;
    left: 8px;
}

.cart-bottom-buttons > button {
    border: 2px solid rgb(170, 9, 9);
    padding: 10px;
    background: none;
    color:rgb(170, 9, 9);
    font-weight: 600;
    margin-left: 15px;
}

.cart-bottom-buttons > button:hover {
    animation: redfade 1s forwards;
}

.empty-cart-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.empty-cart-message {
    color:rgb(170, 9, 9);
}

.browse-products {
    margin-top: 30px;
    border: 2px solid rgb(170, 9, 9);
    background: transparent;
    color:rgb(170, 9, 9);
    height: 40px;
    font-weight: 600;
}

.browse-products:hover {
    animation: redfade 1s forwards;
}

@keyframes deletefade {
    0% {
        color: rgb(170, 9, 9);
        background: white;
    }
    100% {
        color:white;
        background: black;
    }
}

@keyframes redfade {
    0%{
        color: rgb(170, 9, 9);
        background: white;
    }
    100% {
        color: white;
        background:rgb(170, 9, 9);
    }
}