.product-image {
    height: 200px;
    width: 237px;
    border: 2px solid rgb(170, 9, 9);
    margin-top: 7px;
    margin-bottom: 7px;
}


.editProduct {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: aliceblue;
    width: 24em;
    height: 30em;
    border: 3px solid bisque;
    border-radius: .6em;
    min-width: 20em;
    padding: 3rem;
    background: white;  
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
/* .products-container{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content:center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-shrink: 10;
    flex-wrap: wrap;
    max-height: 300px;
    min-width:600px;
    margin-top:20vh;
    z-index:-1;
} */
.products-container{
    margin-top: 5em;
}
.products{
    display: inline-block;
    padding-left:5px;
    padding-bottom: 4px;
    margin: 30px;
    width: 250px;
    border-bottom: 3px solid bisque;
    border-right: 3px solid bisque;
    box-shadow: 2px 4px 7px rgb(170, 9, 9);
    font-size: smaller;
    font-family: 'Bungee', cursive;
}



.product-description{
    width: 220px;
}
