
.orders {
    display:inline-block;
    margin: 30px;
    margin-top: 0;
    width: 150px;
    border-bottom: 2px solid bisque;
    border-right: 2px solid bisque;
    box-shadow: 2px 4px 7px rgb(170, 9, 9);
    padding: .5em;
    font-family:'Courier New', Courier, monospace;
    font-size: large;
    color:black;
}

.reviews {
    border-bottom: 2px solid bisque;
    border-right: 2px solid bisque;
    box-shadow: 2px 4px 7px rgb(170, 9, 9);
    margin-bottom: .8em;
    margin-left: 2em;
    padding: .5em;
    font-family: 'Bungee', cursive;
    font-size: large;
    color: rgb(170, 9, 9);
}

.profile-headers {
    margin-left: .7em;
    color: rgb(170, 9, 9);
    font-family: 'Bungee inline', cursive;
}

.profile-container {
    margin-top: 5em;
}

.orderFilter {
    display: flex;
    align-items: center;
    margin-left: 2em;
    flex-direction: row;
    font-family: 'Bungee', cursive;
    color: rgb(170, 9, 9);
}

.orders-container {
    margin-top: 2em;
    margin-left: 1em;
    display: flex;
    flex-direction: row;
}