/* import fonts here */

* {
  box-sizing: border-box;
}

:root {
  /* define variables here */
  --green: green;
  --red: red;
  --blue: blue;
}

html {
  touch-action: manipulation;
}
a{
  color:rgb(170, 9, 9);
  text-shadow: none;
}
body {
  display:flex;
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fff;
  overflow-x: hidden;
  font-size: 16px;
}

#navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-link-container {
  display: flex;
  justify-content: space-between;
}

.header-link {
  margin: .5em 1.5em;
  text-decoration: none;
  cursor: pointer;
  color:white
}

.header-link:last-child {
  margin-right: 2rem;
}

.cart-button {
  background:rgb(170, 9, 9);
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  cursor: pointer;
  margin: .5em 1.5em;
  font-family: 'Bungee Inline', cursive; 
  font-size:larger;
  color: white;
}

a:link{
  color: white;
  text-decoration: none;
  font-family: 'Bungee Inline', cursive; 
  font-size:small;

}

a:hover{
  color:bisque;
}
#navbar {
  overflow: hidden;
  background-color: rgb(170, 9, 9);
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  height: 2em;
  margin-bottom: 9em;
  padding-top: 2em;
  padding-bottom: 2em;
  
}
#filterNavbar {
  overflow: hidden;
  background-color: rgb(170, 9, 9);
  position: fixed;
  top: 4em; 
  width: 23%; 
  height: 3em;
  border: 2px bisque;
  padding-left: 1em;
  padding-top: .3em;
  color:#fff
}

@media only screen and (max-width: 768px) {
  body {
      font-size: 14px;
  }

  #navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 6px;
  }

  #title{
    color:rgb(222, 197, 108);
    font-size: 10px;
    font-family: 'Bungee Shade', cursive;
    margin: 5px;
    padding: 5px;

  }

  #title:hover {
    color: rgb(248, 219, 117);
  } 

  a:link{
    color: white;
    text-shadow: 2px 2px 1px rgba(0,0,0,0.3), 0 0px 15px rgb(96, 90, 90), 0 0 5px #eeefc1, 0 0 30px #e8e9cc;
    text-decoration: none;
    font-family: 'Bungee Inline', cursive; 
    font-size: 5px;
  
  }
  #filterNavbar {
    overflow: hidden;
    background-color: rgb(170, 9, 9);
    position: fixed;
    top: 3em; 
    width: 100%; 
    height: 4em;
    text-shadow: 2px 2px 1px rgba(0,0,0,0.3), 0 0px 10px #fff, 0 0 10px #eceea6, 0 0 15px #eceea6;
    border: 2px bisque;
    padding-left: 1em;
    padding-top: .3em;
    color:#fff;
    font-size: x-small;
  }

}