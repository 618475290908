.single-product-container {
    margin-top: 5em;
}

.single-product-headers {
    margin-left: 1em;
    color: rgb(170, 9, 9);
    font-family: 'Bungee inline', cursive;
}

.single-product {
    margin-left: 3em;
    border-bottom: 1px solid bisque;
    border-right: 1px solid bisque;
    box-shadow: 2px 4px 7px rgb(170, 9, 9);
    width: 300px;
    font-family: "Bungee", cursive;
    padding-left: 7.5px;
    padding-bottom: .2em;
}

.single-product-image {
    height: 240px;
    width: 285px;
    margin-top: 10px;
    margin-bottom: 10px;
}
 

input[type=number]{
    width: 20%;
    padding: 4px;
    border-radius: 15px;
    text-align: center;
    font-size: 14px;
    margin-left: .6em;
    margin-right: .6em;
    border-color: rgb(170, 9, 9);
}

/* Reviews */

.review-comment {
    margin-left: 35px;
    height: 150px;
    width: 500px;
    border: 2px solid rgb(170, 9, 9);
    resize: none;
}

.review-comment:focus {
    border: none;
}

.review-rating-submit-container {
    margin-left: 25px;
    margin-top: 10px;
}

.no-review-message {
    margin-left: 35px;
    color:rgb(170, 9, 9);
    margin-bottom: 50px;
}